<template>
  <v-container class="pa-16">
    <v-btn
      fab
      text
      small
      color="grey darken-2"
      @click="switchTimesheetRapport('-')"
    >
      <v-icon small> mdi-chevron-left </v-icon>
    </v-btn>
    <v-btn
      fab
      text
      small
      color="grey darken-2"
      @click="switchTimesheetRapport('+')"
    >
      <v-icon small> mdi-chevron-right </v-icon>
    </v-btn>
    <v-data-table
      :headers="headers"
      :items="timesheet"
      class="elevation-0"
      disable-pagination
      hide-default-footer
      @click:row="handleTableClick"
    >
      <template v-slot:no-data> Aucune ligne dans ce rapport </template>
      <template v-slot:top>
        <v-toolbar flat class="mb-6">
          <v-toolbar-title id="toolbar-title">{{
            $route.query.date | moment("dddd DD/MM/YYYY")
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="modificationDialog" max-width="600px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <Button
                v-bind="attrs"
                v-on="on"
                class="mb-2"
                action_name="Demande modification"
                color="primary"
                text
                v-show="expired === true && modifiable === false"
                @clicked="modificationDialog = true"
              />
            </template>
            <v-card>
              <v-card-title class="justify-space-between">
                <span class="headline">Demande de Modification</span>
                <v-btn
                  color="black"
                  dark
                  icon
                  right
                  @click="modificationDialog = false"
                >
                  <v-icon color="#B2BBAB">mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="isValid"
                  @keyup.native.enter="requestModification"
                >
                  <text-input
                    id="raison"
                    v-model="requestItem.raison"
                    label="Raison *"
                    :rules="requiredRules"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions class="pb-3 pt-0 pr-3">
                <v-spacer></v-spacer>
                <Button
                  :loading="loadingRequest"
                  :disabled="!isValid"
                  action_name="Demander"
                  color="secondary"
                  @clicked="requestModification"
                />
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialog" max-width="418px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <Button
                v-bind="attrs"
                v-on="on"
                class="mb-2"
                action_name="Ajouter"
                color="primary"
                text
                @clicked="openAddDialog(true)"
                v-show="expired === false"
              />
            </template>
            <v-card>
              <v-card-title class="justify-space-between">
                <span class="headline">{{ formTitle }}</span>
                <v-btn ref="close" color="black" icon right @click="close">
                  <v-icon color="#B2BBAB">mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-form ref="form" style="width: 100%" v-model="isValid">
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <AutoComplete
                          id="dossier"
                          label="Projet*"
                          v-model="editedItem.dossier"
                          :items="items"
                          item-text="name"
                          item-value="id"
                          @change="handleDossierSelect"
                          return-object
                          :rules="requiredRules"
                          ref="dossier"
                        />
                      </v-col>
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <AutoComplete
                          id="operation"
                          label="Type d'opération*"
                          v-model="editedItem.operation"
                          :items="operations"
                          item-text="name"
                          return-object
                          :rules="requiredRules"
                          @change="handleOperationChange"
                        />
                      </v-col>
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <text-input
                          id="title"
                          v-model="editedItem.title"
                          label="Code de l'opération"
                          v-if="!showScrumInput && !showBacklogInput"
                        />
                        <AutoComplete
                          id="title_scrum"
                          label="Code de l'opération"
                          v-model="editedItem.title"
                          :items="scrum_operations"
                          :rules="requiredRules"
                          v-if="showScrumInput"
                        />
                        <AutoComplete
                          id="title_backlog"
                          label="Code de l'opération"
                          v-model="editedItem.title"
                          :items="backlog_operations"
                          :rules="requiredRules"
                          v-if="showBacklogInput"
                        />
                      </v-col>
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <AutoComplete
                          id="temps"
                          v-model="editedItem.duration"
                          label="Durée*"
                          item-text="text"
                          item-value="value"
                          :items="times"
                          :rules="requiredRules"
                        />
                      </v-col>
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <TextArea
                          id="details"
                          label="Détails"
                          v-model="editedItem.details"
                        />
                      </v-col>
                    </v-form>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="pb-6 btn-actions">
                <Button
                  action_name="Continuer"
                  color="#86CC26"
                  @clicked="save(true)"
                  v-if="!showEditButton"
                  :loading="loadingContinue"
                />
                <Button
                  action_name="Ajouter"
                  color="#11887E"
                  text
                  @clicked="save(false)"
                  v-if="!showEditButton"
                  :loading="loadingAdd"
                />
                <Button
                  action_name="Enregistrer"
                  color="#86CC26"
                  text
                  @clicked="update"
                  v-if="showEditButton"
                  :loading="loadingEdit"
                />
                <Button
                  action_name="Supprimer"
                  color="#11887E"
                  text
                  v-if="showEditButton && showDeleteButton"
                  @clicked="deleteItem"
                />
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!--          <v-dialog v-model="dialogUpdate" max-width="418px">-->
          <!--            <v-card>-->
          <!--              <v-card-title  class="justify-space-between">-->
          <!--                <span class="headline">Modifier code de tâche</span>-->
          <!--                <v-btn ref="close" color="black" icon right @click="dialogUpdate=false">-->
          <!--                  <v-icon color="#B2BBAB">mdi-close</v-icon>-->
          <!--                </v-btn>-->
          <!--              </v-card-title>-->
          <!--              <v-card-text>-->
          <!--                <v-container>-->
          <!--                  <v-row>-->
          <!--                    <v-form ref="form" style="width: 100%" v-model="isValid">-->
          <!--                      <v-col class="pa-0" cols="12" md="12" sm="6">-->
          <!--                        <text-input id="title" v-model="updateItem.title" label="Code de l'opération" />-->
          <!--                      </v-col>-->
          <!--                    </v-form>-->
          <!--                  </v-row>-->
          <!--                </v-container>-->
          <!--              </v-card-text>-->
          <!--              <v-card-actions class="pb-6 btn-actions">-->
          <!--                <Button action_name="Enregistrer" color="#86CC26" text @clicked="updateTitle" :loading="loadingEdit"/>-->
          <!--              </v-card-actions>-->
          <!--            </v-card>-->

          <!--          </v-dialog>-->
          <v-dialog v-model="dialogDelete" max-width="500px" persistent>
            <v-card>
              <v-card-title class="headline"
                >Etes vous sûr de supprimer cette ligne ?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="dark darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  color="secondary darken-1"
                  text
                  @click="deleteLine"
                  :loading="loadingDelete"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogEdit" max-width="500px" persistent>
            <v-card>
              <v-card-title class="headline"
                >Etes vous sûr de modifier cette ligne ?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="dark darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="secondary darken-1" text @click="update"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.duration="{ item }">
        {{ item.duration | moment_time }}</template
      >
    </v-data-table>
  </v-container>
</template>
<script>
import { Button, AutoComplete, TextArea, TextInput } from "@/components/base";
// eslint-disable-next-line no-unused-vars
import {
  addTimeSheetLine,
  deleteTimeSheetLine,
  getTimeSheet,
  updateTimeSheetLine,
  navigationTimeSheet,
} from "@/services/timesheet.api";
import { getUserDossiers, getUserRoleInDossier } from "@/services/dossiers.api";
import times from "@/assets/data/times.json";
import scrum_operations from "@/assets/data/scrum_operations.json";
import backlog_operations from "@/assets/data/backlog_operations.json";
import { addProlongation } from "@/services/prolongation.api";
const moment = require("moment");
export default {
  components: {
    Button,
    TextInput,
    AutoComplete,
    TextArea,
  },
  data: () => ({
    updateItem: {},
    dialogUpdate: false,
    modificationDialog: false,
    raison: "",
    loadingRequest: false,
    loadingAdd: false,
    loadingContinue: false,
    loadingEdit: false,
    loadingDelete: false,
    scrum_operations: scrum_operations,
    backlog_operations: backlog_operations,
    timesheetLine: {},
    dialogEdit: false,
    isValid: true,
    times: times,
    text: "",
    expired: null,
    modifiable: null,
    items: [],
    operations: [],
    dialog: false,
    showScrumInput: false,
    showBacklogInput: false,
    dialogDelete: false,
    headers: [
      {
        text: "Dossier",
        align: "start",
        sortable: true,
        value: "dossier.name",
      },
      { text: "Type d'opération", value: "operation.name" },
      { text: "Code d'opération", value: "title" },
      { text: "Détails", value: "details" },
      { text: "Durée", value: "duration", width: "50px" },
      // { text: "Actions", value: "actions", sortable: false }
    ],
    timesheet: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    showEditButton: false,
    showDeleteButton: false,
    requestItem: {},
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nouvelle opération"
        : "Modifier opération";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    requestModification() {
      this.requestItem.type = 2;
      this.requestItem.date = this.$route.query.date;
      this.loadingRequest = true;
      addProlongation(this.requestItem).then(() => {
        this.loadingRequest = false;
        this.$refs.form.reset();
        this.modificationDialog = false;
        this.$swal.fire({
          title: "Votre demande de modification a été enregistré",
        });
      });
    },
    switchTimesheetRapport(direction) {
      const obj = {
        direction: direction,
        date: this.$route.query.date,
      };
      navigationTimeSheet(obj).then((response) => {
        if (response.status === 200) {
          if (response.data.date) {
            const prevOrNextDay = moment(new Date(response.data.date)).format(
              "YYYY-MM-DD"
            );
            this.$router.push({
              path: "timesheet-detail",
              query: { date: prevOrNextDay },
            });
          }
        }
      });
    },
    handleOperationChange(item) {
      if (item.name === "Manifestation SCRUM") {
        this.showScrumInput = true;
        this.showBacklogInput = false;
      } else if (item.name === "Gestion backlog") {
        this.showScrumInput = false;
        this.showBacklogInput = true;
      } else {
        this.showScrumInput = false;
        this.showBacklogInput = false;
      }
    },
    handleTableClick(row) {
      if (!this.expired || this.modifiable) {
        this.editItem(row);
        this.handleDossierSelect();
        this.showEditButton = true;
        this.showDeleteButton = true;
      }
      if (this.modifiable) {
        this.showDeleteButton = false;
      }
      // if(this.modifiable){
      //   this.editedIndex = this.timesheet.indexOf(row);
      //   this.dialogUpdate = true;
      //   this.updateItem.id = row.id
      //   this.updateItem.title = row.title
      // }
    },
    handleDossierSelect() {
      getUserRoleInDossier(this.editedItem.dossier.id).then((response) => {
        this.operations = response.data;
      });
    },
    getTimeSheetLines() {
      getTimeSheet(this.$route.query.date).then((response) => {
        this.timesheet = response.data.data;
        this.expired = response.data.expired;
        this.modifiable = response.data.modifiable;
      });
    },
    initialize() {
      this.getTimeSheetLines();
      getUserDossiers().then((response) => {
        for (const key in response.data) {
          this.items.push(response.data[key].dossier);
        }
      });
    },

    editItem(item) {
      this.editedIndex = this.timesheet.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem() {
      this.dialog = false;
      this.editedItemID = this.editedItem.id;
      this.dialogDelete = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        // this.editedItem = Object.assign({}, this.defaultItem);
        // this.editedIndex = -1;
        this.showEditButton = false;
        this.showDeleteButton = false;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteLine() {
      this.loadingDelete = true;
      deleteTimeSheetLine(this.editedItemID).then(() => {
        this.loadingDelete = false;
        this.timesheet.splice(this.editedIndex, 1);
        this.dialog = false;
        this.showEditButton = false;
        this.showDeleteButton = false;
        this.closeDelete();
        this.$swal.fire({ title: "Ligne supprimer avec succés", timer: 800 });
      });
    },
    update() {
      this.loadingEdit = true;
      updateTimeSheetLine(this.editedItem).then((response) => {
        this.loadingEdit = false;
        if (response.data.statusCode === 400) {
          this.$swal.fire({
            icon: "warning",
            title: response.data.message,
            timer: 5000,
          });
        } else {
          Object.assign(this.timesheet[this.editedIndex], this.editedItem);
          this.close();
          this.$swal.fire({ title: "Ligne modifiée avec succés", timer: 800 });
        }
      });
    },
    openAddDialog(dialogStat) {
      this.editedIndex = -1;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      if (dialogStat) {
        this.dialog = dialogStat;
      }
    },
    save(dialogStat) {
      if (!this.isValid) {
        this.$refs.form.validate();
        return;
      }
      this.editedItem.date = this.$route.query.date;
      dialogStat ? (this.loadingContinue = true) : (this.loadingAdd = true);
      addTimeSheetLine(this.editedItem).then((response) => {
        dialogStat ? (this.loadingContinue = false) : (this.loadingAdd = false);
        if (response.data.statusCode === 400) {
          this.$swal.fire({
            icon: "warning",
            title: response.data.message,
            timer: 5000,
          });
        } else {
          this.getTimeSheetLines();
          this.close();
          this.openAddDialog(dialogStat);
          this.$swal.fire({ title: "Ligne ajoutée avec succès" });
        }
      });
    },
  },
};
</script>
<style scoped></style>
